@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body,
  #root {
    @apply bg-black
        text-white;
  }
}

@layer components {
  /* scrollbar */
  ::-webkit-scrollbar {
    @apply rounded-xl w-1.5;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-gray-900 rounded-xl bg-opacity-80;
  }

  /* scroller */
  .scroller {
    height: 50px;
    line-height: 50px;
    float: left;
    margin-left: 5px;
    overflow: hidden;
  }

  .scroller span {
    display: block;
    font-size: 200%;
  }

  .inner {
    margin-top: -200px;
  }

  .animate-scroll {
    -webkit-animation: scroll 10s 1 ease-out;
    -moz-animation: scroll 10s 1 ease-out;
    animation: scroll 10s 1 ease-out;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  main::before {
    animation: grain 8s steps(10) infinite;
    background-image: url("/public/noise.png");
    content: "";
    height: 300%;
    left: -50%;
    opacity: 0.5;
    position: fixed;
    top: -100%;
    width: 300%;
    pointer-events: none;
  }

  @keyframes grain {
    0%,
    100% {
      transform: translate(0, 0);
    }
    10% {
      transform: translate(-5%, -10%);
    }
    20% {
      transform: translate(-15%, 5%);
    }
    30% {
      transform: translate(7%, -25%);
    }
    40% {
      transform: translate(-5%, 25%);
    }
    50% {
      transform: translate(-15%, 10%);
    }
    60% {
      transform: translate(15%, 0%);
    }
    70% {
      transform: translate(0%, 15%);
    }
    80% {
      transform: translate(3%, 35%);
    }
    90% {
      transform: translate(-10%, 10%);
    }
  }

  @keyframes scroll {
    9% {
      margin-top: 0px;
    }
    18% {
      margin-top: 0px;
    }
    27% {
      margin-top: -50px;
    }
    36% {
      margin-top: -50px;
    }
    45% {
      margin-top: -100px;
    }
    54% {
      margin-top: -100px;
    }
    63% {
      margin-top: -150px;
    }
    72% {
      margin-top: -150px;
    }
    81% {
      margin-top: -150px;
    }
    90% {
      margin-top: -200px;
    }
    100% {
      margin-top: -200px;
    }
  }

  /* loader */
  .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
